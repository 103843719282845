import React, {useMemo, useState} from "react";
import {Companies, DividerWithArrows} from "components/shared/homepage";
import {Swiper, SwiperSlide} from "swiper/react";
import classNames from "classnames";
import StoryCard, {SuccessStory} from "components/success-stories/storyCard";
import CoverImage from "/static/workfinder/images/home/homepage/coverimage.png";
import vector from "/static/workfinder/images/home/v2/Vector.png";
import styles from "./index.module.scss";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal/Modal";
import {Body, Button as ButtonFromLibrary, Heading} from "workfinder-components";
import featuredImage1 from "/static/workfinder/images/home/homepage/image1.png";
import featuredImage2 from "/static/workfinder/images/home/homepage/image2.png";
import featuredImage3 from "/static/workfinder/images/home/homepage/image3.png";
import featuredImage4 from "/static/workfinder/images/home/homepage/image4.png";
import featuredImage5 from "/static/workfinder/images/home/homepage/image5.png";
import featuredImage6 from "/static/workfinder/images/home/homepage/image6.png";
import featuredImage7 from "/static/workfinder/images/home/homepage/image7.png";
import featuredImage8 from "/static/workfinder/images/home/homepage/image8.png";
import featuredImage9 from "/static/workfinder/images/home/homepage/image9.png";
import featuredImage10 from "/static/workfinder/images/home/homepage/iris-logo.png";
import featuredImage11 from "/static/workfinder/images/home/homepage/f4s-logo.png";
import featuredImage12 from "/static/workfinder/images/home/homepage/martlet-logo.png";
import featuredImage13 from "/static/workfinder/images/home/homepage/gti-logo.png";
import headerStories from "/static/workfinder/images/home/homepage/headerStories.png";
import superpowerWoman from "/static/workfinder/images/home/homepage/SuperpowerWoman.png";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.min.css";
import "swiper/swiper-bundle.css";
import SwiperCore, {Autoplay, Pagination, Navigation} from "swiper";

SwiperCore.use([Autoplay, Pagination, Navigation]);

import {useSuccessStoriesQuery} from "services/queries";

const cardsTalent = [
  {
    id: 1,
    title: "Perfect matches for you",
    description: "We recommend to you flexible work opportunities at organisations changing the world matched to your skills, interests and passion.",
    featuredImage: featuredImage1,
  },
  {
    id: 2,
    featuredImage: featuredImage2,
    title: "Support to grow",
    description: "Receive personalised feedback and guidance with access to courses, masterclasses and other opportunities to maximise your success.",
  },
  {
    id: 3,
    featuredImage: featuredImage3,
    title: "Training on the job",
    description: "Access thousands of free courses, mentoring and masterclasses from industry leaders to help you during your placement.",
  },
];

const cardsEmployers =[
  {
    id: 1,
    title: "Save time",
    description: "No more sifting. Receive a matched shortlist of applicants within 72 hours.",
    featuredImage: featuredImage4,
  },
  {
    id: 2,
    title: "Collaborate",
    description: "Collaborate with your team to evaluate applicants quickly.",
    featuredImage: featuredImage5,
  },
  {
    id: 3,
    title: "Support talent growth",
    description: "Declined candidates are upskilled to be more successful next time.",
    featuredImage: featuredImage6,
  },
  {
    id: 4,
    title: "Interview and offer",
    description: "Interview candidates and offer a role directly on platform.",
    featuredImage: featuredImage7,
  },
  {
    id: 5,
    title: "Training and mentoring",
    description: "Personalised candidate feedback with training and mentoring to help them progress.",
    featuredImage: featuredImage8,
  },
  {
    id: 6,
    title: "Certificates, contracts",
    description: "Contracts, resources and smart certificates to support your placement.",
    featuredImage: featuredImage9,
  },
];

const cardsPartners=[
  {
    id: 1,
    title: "IRIS",
    featuredImage: featuredImage10,
    cardClass: "col-xs-6",
  },
  {
    id: 2,
    title: "Founders 4 Schools",
    featuredImage: featuredImage11,
    cardClass: "col-xs-6",
  },
  {
    id: 3,
    title: "Martlet",
    featuredImage: featuredImage12,
    cardClass: "col-xs-6",
  },
  {
    id: 4,
    title: "GTI",
    featuredImage: featuredImage13,
    cardClass: "col-xs-6",
  },
];
function BlueButton ({title, className=styles.blueButton, target="_blank"}):JSX.Element {
  let link;

  switch (title) {

  case "For Recruiters":
    link = `${window.location.origin}/recruiter`;
    break;
  case "For Talent":
    link=`${window.location.origin}/member`;
    break;
  case "For Partners":
    link=`${window.location.origin}/client`;
    break;
  case "Contact us":
    link=`${window.location.origin}/recruiter/demo`;
    break;
  case "Sign up free":
    link = `${window.location.origin}/signup`;
    break;
  default:
    break;
  }

  return (
    <ButtonFromLibrary
      baseComponent="a"
      kind="primary"
      className={className}
      href={link}
      size="large"
      target={target}
    >
      {title} <img alt="arrow-right" className="ml-2" src={vector}></img>
    </ButtonFromLibrary>
  );
}

function CoverPage():JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const VideoModal = ({onClose, ...restProps}):JSX.Element => {
    return (
      <Modal name="videoModal" onClose={onClose} {...restProps} className={styles.VideoModal}>
      <iframe
            src="https://www.youtube.com/embed/MOGU5bhnC-k?autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            autoPlay
            width="1020"
            height="600"
            style={{ maxWidth: '100%', height: '100%', padding:'5%' }}
          />
      </Modal>
    );
  };

  return (
    <div className={classNames("container", styles.firstSection)}>
      <div className="row">
        <div className="col-12 col-md-7">
          <Heading size="h1" className={styles.heading}>
           Recruit and advance your career like a superhero.<br></br>
            {/* <span className={styles.blue}>We bring you together.</span> */}
          </Heading>
          <img src={superpowerWoman} alt="cover-image" className={classNames("img-fluid d-block d-md-none", styles.coverImageMobile)}></img>
          <Heading size="h4" className="font-weight-normal">
          Superpower helps you attract and develop skilled talent ideal for the real time requirements of your business.
          </Heading>
          <div className={styles.buttonContainer}>
            <BlueButton title="Sign up free" target="_self" />
            <ButtonFromLibrary
              baseComponent="a"
              kind="secondary"
              size="large"
              onClick={handleOpenModal}
            >
                Watch demo
            </ButtonFromLibrary>
            {isModalOpen && <VideoModal onClose={handleCloseModal} />}
          </div>
        </div>

        <div className="col-12 col-md-5">
          <img src={superpowerWoman} alt="cover-image" className={classNames("img-fluid d-none d-md-block", styles.coverImageDesktop)}></img>
        </div>
      </div>
    </div>
  );
}

function Card({title, description, featuredImage, cardClass=""}):JSX.Element {
  return (
    <div
      className={
        classNames(
          "mt-sm-2",
          styles.card,
          cardClass?cardClass:"col",
          !description && styles.noDescription,
        )
      }
    >
      <img src={featuredImage} className="w-100"></img>
      <Heading size="h4" className={description?"mt-3":"text-center"}>{title}</Heading>
      <Body size="large">{description}</Body>
    </div>
  );
}


export function FeaturedSection({cards, title1, subtitle, paragraph, buttonTitle}):JSX.Element {
  return (
    <div className={styles.featuredSection}>
      <div className="container">
        <Heading size="h4" className="text-center mx-auto">{subtitle}</Heading>
        <Heading size="h2" className={classNames(styles.sectionTitle, "mx-auto text-center")}>{title1}</Heading>
        <Body size="large" className="mx-auto text-center">{paragraph}</Body>
      </div>
      <div className={classNames("row justify-content-center", styles.gap)} >
        {cards.map(cardProps => <Card key={cardProps.id} {...cardProps}/>)}
      </div>
      {buttonTitle && (
        <div className={classNames(styles.buttonContainer, "justify-content-center")}>
          <BlueButton title={buttonTitle} target="_self" />
        </div>)
      }
    </div>
  );
}

function SuccessStories(): JSX.Element {
  const {data: successStoriesData, isLoading} = useSuccessStoriesQuery();
  const successStories: SuccessStory[] = useMemo(() => {
    if (!isLoading && successStoriesData && successStoriesData.results) {
      return successStoriesData.results.slice(0, 3);
    }
    else {
      return [];
    }
  }, [successStoriesData]);

  return (
    <div className="container-fluid text-center bg-white">
      <div className={classNames(styles.successStoryContainer, "row")}>
        <div className="col-12 p-0">
          <div className={styles.header}>
            <img src={headerStories} alt="heart" className="img-fluid mx-auto"></img>
          </div>
          <div className={styles.content}>
            <Swiper
              slidesPerView={1}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              breakpoints={{
                250: {slidesPerView: 1},
                768: {slidesPerView: 2},
                992: {slidesPerView: 3},
              }}
            >
              {successStories.map( (successStory) => {
                return (
                  <SwiperSlide key={successStory.username} className="d-flex h-auto">
                    <div className="d-flex mx-auto">
                      <StoryCard key={successStory.username} story={successStory}/>
                    </div>
                  </SwiperSlide>);
              })}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Button
            baseComponent="a"
            kind="blue-primary"
            size="large"
            className={classNames(styles.moreStories, "mx-auto")}
            href={`${window.location.origin}/stories`}
            target="_self"
          >
            More success stories <img alt="arrow-right" className="ml-2" src={vector}></img>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default function Home(): JSX.Element {
  return (
    <div className={styles.sections}>
      <CoverPage />
      <Companies title="Loved by awesome companies like"/>
      <FeaturedSection
        cards={cardsTalent}
        title1="Everything you need to achieve your professional goals"
        subtitle="For Talent"
        paragraph="We recommend you personalised work opportunities at innovative companies supported with free courses, masterclasses and mentoring to build your skills."
        buttonTitle="For Talent"
      />
      <DividerWithArrows text="The future of skills-based hiring"/>
      <FeaturedSection
        cards={cardsEmployers}
        title1="Everything you need to find skilled talent on-demand"
        subtitle="For Recruiters"
        paragraph="Superpower gives you the freedom to choose how you work with your team, whether you’re hiring 1 intern or 20. We help you find talent to supercharge your organisation. "
        buttonTitle="For Recruiters"
      />
      <DividerWithArrows text="Let’s create a better future together"/>
      <FeaturedSection
        cards={cardsPartners}
        title1="Interested in joining forces?"
        subtitle="For Partners"
        paragraph="Partner with us to eradicate the skills crisis and drive growth for the economy helping your network to succeed.
        Together, we can create a better future. Some of our awesome partners include"
        buttonTitle="Contact us"
      />
      <SuccessStories/>
      <Companies/>
    </div>
  );
}
